<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CAlert color="primary" class="m-0 py-1">
          {{ currentDateTime }}
        </CAlert>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from "./AppHeaderDropdownAccnt";
export default {
  name: "AppHeader",
  components: {
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      currentDateTime: "",
    };
  },
  methods: {
    updateTime() {
      const options = {
        weekday: "long",
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      };
      const date = new Date();
      const dtString = date.toLocaleString("id-ID", options);
      const fdtString = dtString.replace(/\./g, ":");
      this.currentDateTime = `${fdtString.replace(",", "")}`;
    },
  },
  created() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
};
</script>
