<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="my-auto py-0" :caret="false">
      <span class="float-end" style="line-height: 1.1;">
        {{ authUser?.name }}
        <br />
        <small class="text-muted" style="font-size: 0.8em">
          <i>{{ tipe }}</i>
        </small>
      </span>
      <!-- <CIcon class="mx-2" icon="cil-settings" size="lg" /> -->
      <CAvatar :src="avatar" size="md" class="me-2" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem @click="logout()">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from "@/assets/images/avatars/1.png";
import { mapActions, mapState } from 'vuex'
export default {
  name: "AppHeaderDropdownAccnt",
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    };
  },
  computed: {
    ...mapState(['authUser']),
    tipe() {
      if (this.authUser?.school_id) {
        return 'Admin Sekolah'
      } else {
        return 'Admin Aplikasi'
      }
    },
  },
  methods: {
    ...mapActions(['prepareLogout']),
    async logout() {
      await this.prepareLogout()
      this.$router.push({ name: 'Login' })
    },
  },
};
</script>
