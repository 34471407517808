import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { getCookie } from "@/libs/utils";

import DefaultLayout from "@/layouts/DefaultLayout";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: DefaultLayout,
    redirect: "/dashboard",
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
      },
      {
        path: "school",
        redirect: "school/list",
        name: "School",
        // component: DefaultLayout,
        children: [
          {
            path: "list",
            name: "List School",
            component: () => import("@/views/school/List"),
          },
          {
            path: "profile/:id",
            name: "Profile School",
            component: () => import("@/views/school/Profile"),
          },
          {
            path: "add",
            name: "Add School",
            component: () => import("@/views/school/Modify"),
          },
          {
            path: "edit/:id",
            name: "Edit School",
            component: () => import("@/views/school/Modify"),
          },
          {
            path: "admin",
            name: "School Admin",
            component: () => import("@/views/school/Admin"),
          },
          {
            path: "teacher",
            redirect: "teacher",
            name: "Teachers",
            // component: DefaultLayout,
            children: [
              {
                path: "",
                name: "List Teacher",
                component: () => import("@/views/teacher/List"),
              },
              {
                path: "add",
                name: "Add Teacher",
                component: () => import("@/views/teacher/Modify"),
              },
              {
                path: "edit/:id",
                name: "Edit Teacher",
                component: () => import("@/views/teacher/Modify"),
              },
            ],
          },
          {
            path: "facility",
            redirect: "facility/list",
            name: "Facilities",
            // component: DefaultLayout,
            children: [
              {
                path: "list",
                name: "List Facility",
                component: () => import("@/views/facility/List"),
              },
              {
                path: "category",
                name: "Facility Category",
                component: () => import("@/views/facility/CategoryList"),
              },
            ],
          },
          {
            path: "extracurricular",
            name: "Extracurricular Data",
            component: () => import("@/views/extracurricular/List"),
          },
          {
            path: "extracurricular/category",
            name: "Extracurricular Category",
            component: () => import("@/views/extracurricular/CategoryList"),
          },
          {
            path: "achievement",
            name: "Achievement Data",
            component: () => import("@/views/achievement/List"),
          },
          {
            path: "gallery",
            name: "Gallery Data",
            component: () => import("@/views/gallery/Card"),
          },
        ],
      },
      {
        path: "student",
        redirect: "student/list",
        name: "Students",
        // component: DefaultLayout,
        children: [
          {
            path: "list",
            name: "List Student",
            component: () => import("@/views/student/List"),
          },
          {
            path: "add",
            name: "Add Student",
            component: () => import("@/views/student/Modify"),
          },
          {
            path: "edit/:id",
            name: "Edit Student",
            component: () => import("@/views/student/Modify"),
          },
        ],
      },
      {
        path: "registration-form",
        redirect: "registration-form/list",
        name: "Registration Form",
        // component: DefaultLayout,
        children: [
          {
            path: "list",
            name: "List Registration Form",
            component: () => import("@/views/registration-form/List"),
          },
          {
            path: "add",
            name: "Add Registration Form",
            component: () => import("@/views/registration-form/Modify"),
          },
          {
            path: "edit/:id",
            name: "Edit Registration Form",
            component: () => import("@/views/registration-form/Modify"),
          },
        ],
      },
      {
        path: "registration",
        redirect: "registration/list",
        name: "Registration",
        // component: DefaultLayout,
        children: [
          {
            path: "list",
            name: "List Registration",
            component: () => import("@/views/registration/List"),
          },
          {
            path: "add",
            name: "Add Registration",
            component: () => import("@/views/registration/FormRegistration"),
          },
          {
            path: "edit/:id",
            name: "Edit Registration",
            component: () => import("@/views/registration/FormRegistration"),
          },
        ],
      },
      {
        path: "user",
        name: "Administrator System",
        component: () => import("@/views/user/List"),
      },
      {
        path: "/messages",
        name: "Messages",
        component: () => import("@/views/messages/MessageList.vue"),
      },
    ],
  },
  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: () => import("@/views/pages/Page404"),
      },
      {
        path: "500",
        name: "Page500",
        component: () => import("@/views/pages/Page500"),
      },
      {
        path: "login",
        name: "Login",
        meta: { noAuth: true },
        component: () => import("@/views/pages/Login"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/pages/Register"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next("/pages/login");
    } else {
      next();
    }
  } else if (to.meta.noAuth) {
    if (isAuthenticated()) {
      next("/dashboard");
    } else {
      next();
    }
  } else {
    if (to.name) {
      next();
    } else {
      next('/pages/404');
    }
  }
});

const isAuthenticated = () => {
  const accessToken = getCookie("accessToken");
  return !!accessToken;
};

export default router;
