import axiosInstance from "@/libs/axios-instance";
import axios from "axios";
import { getCookie, setCookie, removeCookie } from "@/libs/utils";
import { createStore } from "vuex";
import Cookies from "js-cookie";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    sidebarVisible: "",
    sidebarUnfoldable: false,
    authUser: {},
    toasts: [],
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
    setAuthUser(state, user) {
      state.authUser = user;
      console.log(state.authUser);
    },
    setLogout(state) {
      state.authUser = null;
    },
    setToast(state, toast) {
      state.toasts.push(toast);
    },
    clearToast(state) {
      state.toasts = [];
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const authData = await axios.post(
          process.env.VUE_APP_API_BASE_URL + "/login/admin",
          {
            email: credentials.email,
            password: credentials.password,
          },
        );
        if (authData.data.token) {
          setCookie("accessToken", authData.data.token);
          commit("setAuthUser", authData.data);
        }
        return authData;
      } catch (error) {
        return error;
      }
    },
    async getUser({ commit }) {
      let usr = Cookies.get('authUser');

      if (usr) {
        commit("setAuthUser", JSON.parse(usr));
        return JSON.parse(usr);
      }
      if (getCookie('accessToken')) {
        await axiosInstance
          .get("me")
          .then((res) => {
            commit("setAuthUser", res.data.user);
            usr = res.data.user;
            // setCookie('authUser', JSON.stringify(usr));
          })
          .catch((e) => e.response);
      } else {
        return null
      }
      return usr;
    },
    async prepareLogout({ commit }, server = true) {
      if (server) {
        await axiosInstance.post("logout").catch(({ response }) => {
          if (response.status == 401) {
            commit("setLogout");
            Cookies.remove('authUser');
            removeCookie("accessToken");
          }
        });
      }
      commit("clearToast");
      commit("setLogout");
      Cookies.remove('authUser');
      removeCookie("accessToken");
    },
    setToast({ commit }, toast) {
      commit("setToast", toast);
    },
    clearToast({ commit }) {
      commit("clearToast");
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: "authUser",
      storage: {
        getItem: (key) => (getCookie(key) ? JSON.parse(getCookie(key)) : null),
        setItem: (key, value) => setCookie(key, JSON.stringify(value)),
        removeItem: (key) => removeCookie(key),
      },
      serialize: (value) => JSON.stringify(value),
      deserialize: (value) => JSON.parse(value),
      reducer: (state) => ({
        sidebarUnfoldable: state.sidebarUnfoldable,
        authUser: state.authUser,
      }),
    }),
  ],
});
