<template>
  <CFooter style="border: 0; background-color: rgba(30, 55, 153, 0.04)">
    <div class="m-auto">
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} Winosa Mitra Bharatadjaya</span
      >
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
