import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import CoreuiVue from "@coreui/vue";
import CIcon from "@coreui/icons-vue";
import { iconsSet as icons } from "@/assets/icons";
import DocsExample from "@/components/DocsExample";
// import VueKinesis from "vue-kinesis";
// import VWave from "v-wave";
// import GlitchedWriter from "vue-glitched-writer";
// import VueTypewriterEffect from "vue-typewriter-effect";

import Echo from "laravel-echo";
window.Pusher = require("pusher-js");
window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: "ap1",
  wsHost: window.location.hostname,
  wsPort: 6001,
  forceTLS: false,
  // encrypted: true,
  // logToConsole: true,
  disableStats: true,
});

const app = createApp(App);

app.config.globalProperties.$toast = (target, toast) => {
  return store.dispatch(target, toast);
};

app.use(store);
app.use(router);
// app.use(VueKinesis);
// app.use(VWave);
app.use(CoreuiVue);
app.provide("icons", icons);
app.provide("setToast", (toast) => store.dispatch('setToast', toast));
app.component("CIcon", CIcon);
app.component("DocsExample", DocsExample);
// app.component("glitched-writer", GlitchedWriter);
// app.component("vue-typewriter-effect", VueTypewriterEffect);

app.mount("#app");
