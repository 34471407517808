export default [
  {
    component: "CNavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: "cil-speedometer",
    role: ["superadmin"],
    school: false,
    badge: {
      color: "primary",
      text: "NEW",
    },
  },
  {
    component: "CNavItem",
    name: "Riwayat Pendaftaran",
    to: "/registration/list",
    icon: "cil-address-book",
    role: ["superadmin"],
    school: false,
  },
  {
    component: "CNavTitle",
    name: "Data Sekolah",
    role: ["superadmin"],
    school: false,
  },
  {
    component: "CNavGroup",
    name: "Data Sekolah",
    to: "/school",
    icon: "cil-house",
    role: ["superadmin"],
    school: false,
    items: [
      {
        component: "CNavItem",
        name: "Daftar Sekolah",
        to: "/school/list",
        role: ["superadmin"],
        school: false,
      },
      {
        component: "CNavItem",
        name: "Form Pendaftaran",
        to: "/registration-form/list",
        role: ["superadmin"],
        school: false,
      },
      {
        component: "CNavItem",
        name: "Admin Sekolah",
        to: "/school/admin",
        role: ["superadmin"],
        school: false,
      },
    ],
  },
  {
    component: "CNavItem",
    name: "Kategori Fasilitas",
    to: "/school/facility/category",
    icon: "cil-notes",
    role: ["superadmin"],
    school: false,
  },
  {
    component: "CNavItem",
    name: "Kategori Ekstrakurikuler",
    to: "/school/extracurricular/category",
    icon: "cil-notes",
    role: ["superadmin"],
    school: false,
  },
  {
    component: "CNavItem",
    name: "Daftar Calon Siswa",
    to: "/student/list",
    icon: "cil-contact",
    role: ["superadmin"],
    school: false,
  },
  {
    component: "CNavTitle",
    name: "Setting",
    role: ["superadmin"],
    school: false,
  },
  {
    component: "CNavItem",
    name: "Daftar Admin",
    to: "/user",
    icon: "cil-people",
    role: ["superadmin"],
    school: false,
  },
];
