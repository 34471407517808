export default [
  {
    component: "CNavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: "cil-speedometer",
    role: ["superadmin","admin"],
    school: true,
    badge: {
      color: "primary",
      text: "NEW",
    },
  },
  {
    component: "CNavItem",
    name: "Riwayat Pendaftaran",
    to: "/registration/list",
    icon: "cil-address-book",
    role: ["superadmin","admin"],
    school: true,
  },
  {
    component: "CNavItem",
    name: "Pesan",
    to: "/messages",
    icon: "cil-envelope-closed",
    role: ["superadmin"],
    // badge: {
    //   color: "primary",
    //   text: "NEW",
    //   shape: "pill",
    // },
  },
  {
    component: "CNavTitle",
    name: "Data Sekolah",
    role: ["superadmin","admin"],
    school: true,
  },
  {
    component: "CNavItem",
    name: "Profil Sekolah",
    to: "/school/profile/my-school",
    icon: "cil-house",
    role: ["superadmin","admin"],
    school: true,
  },
  {
    component: "CNavItem",
    name: "Form Pendaftaran",
    to: "/registration-form/list",
    icon: "cil-notes",
    role: ["superadmin","admin"],
    school: true,
  },
  {
    component: "CNavItem",
    name: "Data Guru",
    to: "/school/teacher",
    icon: "cil-people",
    role: ["superadmin","admin"],
    school: true,
  },
  {
    component: "CNavItem",
    name: "Admin Sekolah",
    to: "/school/admin",
    icon: "cil-people",
    role: ["superadmin"],
    school: true,
  },
];
