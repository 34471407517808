import { defineComponent, h, onMounted, ref, resolveComponent, useAttrs } from "vue";
import { RouterLink, useRoute } from "vue-router";
// import { useStore } from "vuex";

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from "@coreui/vue";
import _nav_app_adm from "@/_nav_app_adm";
import _nav_school_adm from "@/_nav_school_adm";

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, "")
    .replace(/(index)?\.(html)$/, "");

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false;
  }

  if (route.hash === link) {
    return true;
  }

  const currentPath = normalizePath(route.path);
  const targetPath = normalizePath(link);

  return currentPath === targetPath;
};

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true;
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child));
  }

  return false;
};

const AppSidebarNav = defineComponent({
  name: "AppSidebarNav",
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    // const store = useStore();
    const attr = useAttrs();
    const route = useRoute();
    const firstRender = ref(true);
    const user = ref(attr.user);

    const checkPermission = (item) => {
      return (
        item.role == '' ||
        (!item.school && !user.value.school_id && item.role.includes(user.value.role) && user.value.role == 'superadmin') ||
        (item.school && user.value.school_id && item.role.includes(user.value.role))
      )
    }
    onMounted(() => {
      firstRender.value = false;
    });

    const renderItem = (item) => {
      if (checkPermission(item)) {
        if (item.items) {
          return h(
            CNavGroup,
            {
              ...(firstRender.value && {
                visible: item.items.some((child) => isActiveItem(route, child)),
              }),
            },
            {
              togglerContent: () => [
                h(resolveComponent("CIcon"), {
                  customClassName: "nav-icon",
                  name: item.icon,
                }),
                item.name,
              ],
              default: () => item.items.map((child) => renderItem(child)),
            },
          );
        }

        return item.to
          ? h(
              RouterLink,
              {
                to: item.to,
                custom: true,
              },
              {
                default: (props) =>
                  h(
                    resolveComponent(item.component),
                    {
                      active: props.isActive,
                      href: 'javascript:;',//props.href,
                      onClick: () => props.navigate(),
                    },
                    {
                      default: () => [
                        item.icon &&
                          h(resolveComponent("CIcon"), {
                            customClassName: "nav-icon",
                            name: item.icon,
                          }),
                        item.name,
                        item.badge &&
                          h(
                            CBadge,
                            {
                              class: "ms-auto",
                              color: item.badge.color,
                            },
                            {
                              default: () => item.badge.text,
                            },
                          ),
                      ],
                    },
                  ),
              },
            )
          : h(
              resolveComponent(item.component),
              {},
              {
                default: () => item.name,
              },
            );
      }
    };

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => user.value.school_id ? 
          _nav_school_adm.map((item) => renderItem(item)) :
          _nav_app_adm.map((item) => renderItem(item)),
        },
      );
  },
});
export { AppSidebarNav };
