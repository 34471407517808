function setCookie(cname, cvalue, exdays = 180) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}

function removeCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function toRP(num = 0, comma = 2) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: comma,
  }).format(parseInt(num) || 0)
}

function stringDate(format = 'dd-mm-yyyy', dt = null, lang = 'id-ID') {
  let d = dt ? new Date(dt) : new Date()
  let hsl = format
  if (format.search(/hh/gi) >= 0) {
    hsl = hsl.replace('hh', new Intl.DateTimeFormat(lang, { hour: '2-digit' }).format(d))
  }
  if (format.search(/ii/gi) >= 0) {
    hsl = hsl.replace('ii', new Intl.DateTimeFormat(lang, { minute: '2-digit' }).format(d))
  }
  if (format.search(/dd/g) >= 0) {
    hsl = hsl.replace('dd', new Intl.DateTimeFormat(lang, { day: '2-digit' }).format(d))
  }
  if (format.search(/mm/g) >= 0) {
    hsl = hsl.replace('mm', new Intl.DateTimeFormat(lang, { month: '2-digit' }).format(d))
  }
  if (format.search(/MM/g) >= 0) {
    hsl = hsl.replace('MM', new Intl.DateTimeFormat(lang, { month: 'long' }).format(d))
  }
  if (format.search(/yyyy/gi) >= 0) {
    hsl = hsl.replace('yyyy', new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(d))
  } else if (format.search(/yy/gi) >= 0) {
    hsl = hsl.replace('yy', new Intl.DateTimeFormat(lang, { year: '2-digit' }).format(d))
  }
  return hsl
}

function longDate(dt) {
  const tgl = new Date(dt)
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  return tgl.toLocaleDateString('id-ID', options)
}

function dateDiff(dt1, dt2) {
  const d1 = new Date(dt1)
  const d2 = new Date(dt2)
  if (d1 < d2) {
    return 0
  }
  const diff = Math.abs(d1 - d2)
  const div = 1000 * 60 * 60 * 24 // milisecond to hour
  return Math.round(diff / div)
}

function createDebounce() {
  let timeout = null;
  return function (fnc, delayMs) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
}

export {
  getCookie,
  setCookie,
  removeCookie,
  toRP,
  stringDate,
  longDate,
  dateDiff,
  createDebounce,
};
